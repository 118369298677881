/*
    Variables - Internal
*/

// -- Technically not needed, but leaving out causes gulp error --
@import "./colors";


// ** Layout Grid **
// *****************
$gridColumns-int: 12; // number of columns
$gridGutterWidth-int: 16px; // padding between columns

// -- Breakpoints --
$bp-xs-int: 480px;
$bp-sm-int: 600px;
$bp-md-int: 768px;
$bp-lg-int: 1025px;
$bp-xl-int: 1440px;

// -- Padding inside containers. 15px @initial, 30px @bp-sm, 15px @bp-lg
$containerPaddingSm-int: 15px;
$containerPaddingLg-int: 30px;


// ** Colors **
// ************
$colorBodyBg-int: map-get($xpo-grey, grey-4); //#dfdfdf
$colorFooterTextNormal-int: map-get($xpo-grey, grey-6);
$colorFooterTextHover-int: #fff;

// ** Typography **
// ****************
$lineHeightBase-int: 1.4;

// -- General text sizes --
$fontSizeSm-int: 0.833333rem;  // 10px
$fontSizeMd-int: 1rem;      // 12px
$fontSizeLg-int: 1.167rem;  // 14px

// -- Titles --
$fontSizeH1-int: 1.5rem;    // 18px
$fontSizeH2-int: 1.33333rem;  // 16px
$fontSizeH3-int: 1.16667rem;  // 14px
$fontSizeH4-int: 1rem;      // 12px
$fontSizeH5-int: 0.83333rem;  // 10px


// ** Measurements **
// *****************
$headerRowHeight-int: 44px;
