// Imports 
@import './blockSize';
@import './extra';
/* stylelint-disable */

// -- Clearfix --
@mixin cf {

    &:before,
    &:after {
      display: table;
      content: " ";
    }
  
    &:after {
      clear: both;
    }
  }
  
  .cf,
  .clearfix {
    @include cf;
  }
  
  // -- Hide visually, but not from screenreader --
  @mixin sr-only {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    border: 0;
  }
  
  .sr-only {
    @include sr-only;
  }
  
  
  // -- forces a flex child to not float --
  @mixin flex-clear {
    flex-basis: 100%;
  }
  
  // -- Flex layout system mixin --
  @mixin xpo-flex-stack{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  // -- Align vertically without flex --
  @mixin vertical-align() {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  
  // -- Box Shadow with defaults --
  @mixin box-shadow($horizontal: 0, $vertical: 2px, $blur: 5px, $spread: 0, $color: rgba(0, 0, 0, 0.13)) {
    box-shadow: $horizontal $vertical $blur $spread $color;
  }
  
  
  // ** Vendor Prefixes **
  // *********************
  
  @mixin user-select($value) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  @mixin input-placeholder {
  
    &::placeholder {
      @content;
    }
  
    &::-moz-placeholder {
      @content;
    }
  
    &::-webkit-input-placeholder {
      @content;
    }
  
    &:-ms-input-placeholder {
      @content;
    }
  }
  
  @mixin cursor-grab {
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  @mixin cursor-grabbing {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  
  
  // ** Copied A11y styles **
  // ************************
  @mixin cdk-a11y {
  
    .cdk-visually-hidden {
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0);
      text-transform: none;
      border: 0;
    }
  }
  
  /**
   * Applies styles for users in high contrast mode. Note that this only applies
   * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
   * attribute, however Chrome handles high contrast differently.
   */
  @mixin cdk-high-contrast {
    @media screen and (-ms-high-contrast: active) {
      @content;
    }
  }

