/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:500');
@import './variables.scss';
@import './assets/styles/custom.scss';

body{
  font-family: "Roboto", sans-serif !important; 
}
// == General form styles ==
input:not([type=checkbox]),
select {
  font-size: $font-size-medium !important;
  height: $input-height;
}

// SPEED-1984 - Removing datepicker selector
input::-webkit-calendar-picker-indicator {
  display: none;
}

input:not([type=checkbox]) {
  padding-left: 6px;
}

textarea {
  resize: none;
}

input[readonly] {
  &:focus {
    outline: auto 5px -webkit-focus-ring-color;
  }
}

.form-control,
.form-control.xpo-input-element {
  background-color: #FFF;
  border: 1px solid map-get($xpo-grey, grey-5);
  height: 30px;
  border-radius: 0;
  padding: 5px 10px;
}

.xpo-hidden {
  display: none !important;
}

.icon-success {
  color: $color-positive;
}

.icon-warning {
  color: $color-warnings;
}

.icon-error {
  color: $color-errors;
}


.bill-button {
  border: none;
  border-radius: $button-border-radius;
  cursor: pointer;
  font-weight: 700;
  height: $headerRowHeight-int - 12px;
  justify-content: center;
  margin: 0 10px 0 0;
  min-width: 100px;
  align-items: center;

  &:hover {
    opacity: 0.75;
  }

  &__primary {
    background-color: $color-actions !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin: 0 10px 0 0;

    &:enabled {
      color: $primary-button-font-color !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: $color-text-disabled !important;
    }
  }

  &__alt-primary {
    background-color: $alt-primary-button-font-color !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

    &:enabled {
      color: $color-actions !important;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  &__secondary {
    background-color: transparent;
    border: none;
    color: $color-actions;

    &:hover {
      background-color: rgba(0, 0, 0, 0.12%);
      color: $color-actions;
    }

    &:focus {
      outline: none;
    }
  }

}

// -------- TODO fix in common --------
.cdk-visually-hidden {
  display: none;
}

.xpo-warning-snack-bar .xpo-snack-bar__status {
  background: $color-warnings !important;
}

.xpo-input-element:-webkit-autofill + .xpo-input-placeholder-wrapper {
  transform: inherit !important;
}

xpo-main {
  height: 100%;
}

::ng-deep {
  .cdk-overlay-pane {
    max-width: none !important;
    min-width: fit-content !important;
  }
}

// select
.xpo-select-underline {
  top: 24px !important;
}

.xpo-select {
  height: 24px !important;

  &:focus {
    outline: -webkit-focus-ring-color auto 3px !important;
  }
}

.xpo-select-trigger,
.xpo-select-value {
  height: 22px !important;

  &:focus {
    outline: none;
  }
}

.xpo-select-underline {
  display: none;
}

.xpo-select-value {
  bottom: 0 !important;
  font-size: $font-size-medium;
  padding-left: 6px;
}

.xpo-select-disabled {
  background-color: map-get($xpo-grey, grey-3);
}

.xpo-option-disabled {
  color: $color-disabled;

  &:hover {
    background-color: initial !important;
  }
}

//SPEED-1988 Removing up and down arrows for number

// Globally remove number arrows
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='checkbox'],
input[type='radio'] {
  appearance: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button{
  font-family: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
  text-rendering: optimizelegibility;
  line-height: 1.15;
}
h1:first-of-type, h2:first-of-type, h3:first-of-type, h4:first-of-type, h5:first-of-type, h6:first-of-type {
  margin-top: 0;
}
.form-control, input, textarea{
  font-family: inherit;
}
// -------------------------------------
