@import '../src/assets/styles/common_vars';
@import '../src/assets/styles/colors';
@import '../src/assets/styles/mixins/mixins';

$max-content-width: 1000px;
$ebol-search-content-width: 1250px;
$headerAndFooterHeight: $headerRowHeight-int + $headerRowHeight-int; // 88px;
$panelContainerHeight: calc(100vh - #{$headerAndFooterHeight}); // 100vh - 88px;
$panelPadding: 10px;
$input-height: 24px;

// COLORS
$color-positive: #00C853;
$color-secondary-positive: #64DD17;
$color-warnings: #FFAB00;
$color-errors: #D50000;
$color-actions: #0091EA;
$color-dividers: #E1E1E1;
$color-selected: #FFFEBB;
$color-white: #FFFFFF;
$color-disabled: rgba(0, 0, 0, 0.35);
$color-info: rgba(0, 0, 0, 0.54);
$color-content: rgba(0, 0, 0, 0.87);
$primary-button-font-color: rgba(255, 255, 255, 0.87);
$dark-button-background-color: #616161;
$alt-primary-button-font-color: rgba(250, 250, 250, 1);
$color-text-gray: #333333;
$color-text-disabled: #bdbdbd;

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;

$font-size-xtra-large: 22px;
$font-size-large: 18px;
$font-size-medium: 14px;
$font-size-smedium: 13px; // Special cases, do not use often.
$font-size-small: 12px;

$xpo-logo-height: 55px;

$button-border-radius: 2px;

// Hazmat
$color-hazmat-header-background: #616161;
$color-hazmat-details-background: #EEEEEE;

$color-explosive-border: #FBD500;
$color-explosive-background: #FEF9D8;

$color-marine-pollutant-border: #3492ED;
$color-marine-pollutant-background: #EBF4FD;

$color-radioactive-border: #B3EA00;
$color-radioactive-background: #F8FDE6;

// values that will be derived from seed.  temp changes for now until integration happens
$font-family-rg: 'Roboto-Regular', 'Roboto Regular', 'Roboto', sans-serif;
$font-family-md: 'Roboto-Medium', 'Roboto Medium', 'Roboto', sans-serif;
$font-family-hv: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;

$text-color-dark-1: rgba(0, 0, 0, 1);
$text-color-dark-2: rgba(0, 0, 0, 0.87);
$text-color-dark-3: rgba(0, 0, 0, 0.7);
$text-color-dark-4: rgba(0, 0, 0, 0.54);

$text-size-header-1: 24px;
$text-size-header-2: 20px;
$text-size-header-3: 18px;

$text-size-body-1: 16px;
$text-size-body-2: 14px;
$text-size-body-3: 12px;

$text-color-link-1: rgba(0, 145, 234, 1);
$text-color-error: #D50000;
$text-color-warning: #FF6D00;
